(function(w) {

	"use strict";

	var offcanvas = {
		init: function() {

			// define elements
			offcanvas.$toggle = $('.trigger--offcanvas-toggle');
			offcanvas.$reveal = $('.trigger--offcanvas-reveal');
			offcanvas.$conceal = $('.trigger--offcanvas-conceal');

			// define variables
			offcanvas.isOpen = false;

			// bind events
			offcanvas.$toggle.bind('click', function(e) { e.preventDefault(); if(offcanvas.isOpen) { offcanvas.conceal(); } else { offcanvas.reveal(); } });
			offcanvas.$reveal.bind('click', function(e) { e.preventDefault(); offcanvas.reveal(); });
			offcanvas.$conceal.bind('click', function(e) { e.preventDefault(); offcanvas.conceal(); });

		},
		reveal: function() {

			// clear previous timers
			clearTimeout(offcanvas.timer);

			// toggle classes
			$('body').addClass('offcanvas--is-active');
			$('body').addClass('offcanvas--is-open');
			offcanvas.timer = setTimeout(function() { $('body').addClass('offcanvas--is-visible'); }, 50);

			// define state
			offcanvas.isOpen = true;

		},
		conceal: function() {

			// clear previous timers
			clearTimeout(offcanvas.timer);

			// toggle classes
			$('body').removeClass('offcanvas--is-active');
			$('body').removeClass('offcanvas--is-visible');
			offcanvas.timer = setTimeout(function() { $('body').removeClass('offcanvas--is-open'); }, 750);

			// define state
			offcanvas.isOpen = false;

		}
	};

	$(document).ready(offcanvas.init);

}(window));