(function(window, undefined) {

    // construct component
    var faq = {
        init: function() {

            // define essential elements
            faq.$element = $('.faq');

            // return if not needed
            if(!faq.$element.length) { return false; }

            // define elements
            faq.$toggle = $('.trigger--faq-toggle');

            // bind events
            faq.$toggle.bind('click', function() { faq.toggler($(this).parents('.faq__item').eq(0)); });

        },
        toggler: function(element) {

            // return if not needed
            if(!element) { return false; }
            if(!element.length) { return false; }

            // define variables
            var thisELEMENT = element;

            // toggle classes
            thisELEMENT.toggleClass('is-open');

            // re-calculate grid
            faq.grid.masonry('layout');

        }
    };

    // wait for window to be loaded
    window.addEventListener('load', faq.init);

    // set return
    return {};

})(window);