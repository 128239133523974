(function(window, undefined) {

    // construct component
    var boxes = {
        init: function() {

            // define essential elements
            boxes.$element = $('.boxes');

            // return if not needed
            if(!boxes.$element.length) { return false; }

            // define elements
            boxes.$list = boxes.$element.find('.boxes__list');

            // append plugin
            boxes.$list.slick({
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: false,
                dots: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 680,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true
                        }
                    }
                ]
            });

            // create watcher
            $.each(boxes.$element, function() {

                var element = $(this);
                var watcher = scrollMonitor.create(element[0]);
                watcher.enterViewport(function() { element.addClass('is-visible'); watcher.destroy(); });

            });
        }
    };

    // wait for window to be loaded
    window.addEventListener('load', boxes.init);

    // set return
    return {};

})(window);