VALIDATOR = (function(w) {

	"use strict";

	var validator = {
		init: function() {

			// define essential elements
			validator.$element = $('form');

			// return if not needed
			if(!validator.$element.length) { return false; }

			// define variables
			validator.errors = 0;
			validator.sent = false;

			// bind events
			validator.$element.submit(validator.validate);
			$('.trigger--validator-keyup').bind('keyup', function() { if(validator.sent) { validator.validate(false, $(this).parents('form')); } });
			$('.trigger--validator-change').bind('change', function() { if(validator.sent) { validator.validate(false, $(this).parents('form')); } });

		},
		validate: function(e, element) {

			// reset errors
			validator.errors = 0;
			validator.sent = true;

			// define fields
			var thisForm = (element) ? element : $(this);
			var requiredField = thisForm.find('input[type=text].validator--required, input[type=password].validator--required, textarea.validator--required').not('.type-dealer');
			var emailField = thisForm.find('input[type=email].validator--required');
			var selectField = thisForm.find('select.validator--required').not('.type-dealer');
			var checkboxField = thisForm.find('input[type=checkbox].validator--required');
			var radioField = thisForm.find('input[type=radio].validator--required');

			// check required fields
			$.each(requiredField, function(i,el){

				if($(this).val() ==""){

					$(this).parents('.target--validator-error').addClass('is-invalid');
					validator.errors++;

				} else {

					$(this).parents('.target--validator-error').removeClass('is-invalid');

				}

			});

			// check e-mail fields
			$.each(emailField, function(i,el){

				var mailReq = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
				if(mailReq.test($(this).val()) == false) {

					$(this).parents('.target--validator-error').addClass('is-invalid');
					validator.errors++;

				} else {

					$(this).parents('.target--validator-error').removeClass('is-invalid');

				}

			});

			// check select fields
			$.each(selectField, function(i, el) {

				if($(this).val() === '0') {

					$(this).parents('.target--validator-error').addClass('is-invalid');
					validator.errors++;

				} else {

					$(this).parents('.target--validator-error').removeClass('is-invalid');

				}

			});

			// check checkbox fields
			$.each(checkboxField, function(i, el) {

				if(!$(this).is(':checked')) {

					$(this).parents('.target--validator-error').addClass('is-invalid');
					validator.errors++;

				} else {

					$(this).parents('.target--validator-error').removeClass('is-invalid');

				}

			});

			// check radio fields
			$.each(radioField, function(i,el){

				var thisParent = $(this).parents('.target--validator-error');
				var checkedFields = thisParent.find('input[type=radio]:checked').length;

				if(checkedFields === 0) {

					$(this).parents('.target--validator-error').addClass('is-invalid');
					validator.errors++;

				} else {

					$(this).parents('.target--validator-error').removeClass('is-invalid');

				}

			});

			// dealer field
			var dealerErrors = 0;
			$.each($('.type-dealer'), function() { if($(this).val() === '' || $(this).val() === '0') { dealerErrors++; } });

			if(dealerErrors >= 2) { validator.errors++; $.each($('.type-dealer'), function() { $(this).parents('.target--validator-error').addClass('is-invalid'); }); }
			else { $.each($('.type-dealer'), function() { $(this).parents('.target--validator-error').removeClass('is-invalid'); }); }


			// check all fields and set return
			if(validator.errors === 0){

				$('.target--validator-errormessage').removeClass('is-visible');
				return true;

			} else {

				$('.target--validator-errormessage').addClass('is-visible');
				return false;

			}

		}
	};

	$(document).ready(validator.init);

	return {
		validate: function(form) { if(validator.sent) { validator.validate(false, form); } }
	};

}(window));