(function(window, undefined) {

    // construct component
    var hero = {
        init: function() {

            // define essential elements
            hero.element = $('.hero');

            // return if not needed
            if(!hero.element.length) { return false; }

            // define elements
            setTimeout(function() { $('body').addClass('hero--is-loaded'); }, 100);

        }
    };

    // wait for window to be loaded
    $('document').ready(hero.init);

})(window);