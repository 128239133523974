var INPUT = (function(window, undefined) {

    // construct component
    var input = {
        init: function() {

            // define essential elements
            input.$element = $('.input');
            input.$input = $('.input input, .input textarea');

            // return if not needed
            if(!input.$input.length) { return false; }

            // bind events
            input.$input.bind('keyup', function() { input.checkState($(this)); });
            input.$input.bind('blur', function() { input.checkState($(this)); });

            // set initial state
            $.each(input.$input, function() { input.checkState($(this)); });

        },
        checkState: function(element) {

            // return if not needed
            if(!element) { return false; }

            // define variables
            var thisELEMENT = element;
            var hasValue = thisELEMENT.val() !== '';

            // set state
            thisELEMENT.parents('.input').eq(0).toggleClass('has-value', hasValue);

        }
    };

    // wait for window to be loaded
    window.addEventListener('load', input.init);

    // set return
    return {
        checkState: function(element) { input.checkState(element); }
    };

})(window);