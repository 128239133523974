(function(window, undefined) {

    // construct component
    var topbar = {
        settings: {
            offset: 100
        },
        init: function() {

            // define essential elements
            topbar.$element = $('.topbar');

            // return if not needed
            if(!topbar.$element.length) { return false; }

            // define variables
            topbar.scrollPos = 0;

            // bind events
            $(window).bind('scroll', function() { topbar.perform(); });

            // set initial state
            topbar.perform();

        },
        perform: function() {

            // define variables
            topbar.scrollPos = $(window).scrollTop();

            // define visibility
            var hasBackground = (topbar.scrollPos > topbar.settings.offset) ? true : false;
            if($('.hero__visual.no-overlay').length) { hasBackground = true; }

            // set class
            $('body').toggleClass('viewport--is-scrolled', hasBackground);

        }
    };

    // wait for window to be loaded
    window.addEventListener('load', topbar.init);

    // set return
    return {};

})(window);